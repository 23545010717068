import React from 'react'
import Layout from "../components/App/Layout"
import NavbarThree from "../components/App/NavbarThree"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServicesOne from '../components/Services/ServicesOne'
import StartProject from '../components/Common/StartProject'
import { Helmet } from "react-helmet"

const Services = () => {
    return (
        <Layout>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <NavbarThree />
            <PageBanner
                pageTitle="Service Agreement" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Service Agreement" 
            />
            <div className="scientist-area pt-100 pb-70">
            <div className="container" style={{maxWidth: "800px"}}>
                <p>
                    Updated December 7, 2022
                </p>
                <p>
                    <strong>This Services Agreement</strong> (the "Agreement") is between BROOKLIME SOFTWARE DESIGN L.L.C ("Company", "BROOKLIME SOFTWARE DESIGN"), and you (the "Customer"). This Agreement is effective as of the date when you purchased and/or started using the Services in any manner (the "Effective Date"), you agree to the Agreement herein. If you do not agree to the Agreement, do not use the Services.
                </p>
                <h3>1. Definitions</h3>
                <p>
                    "Service(s)" means Company's SaaS-based subscription services purchased by Customer and provided by Company under this Agreement as specifically identified in the applicable Invoice. All Services shall be specifically identified in the Invoice.
                </p>
                <p>
                    "Invoice" means the invoice issued through payment services by Company to Customer describing the Services, including without limitation, the type, fees, limitations and duration of the Services. All Invoices shall be subject to and incorporated into this Agreement.
                </p>
                <p>
                    "Fee" means the amount Customer is required to pay for the Services as set forth on the applicable Invoice.
                </p>
                <h3>2. Services and Support</h3>
                <p>
                    2.1	Subject to the terms of this Agreement, Company will use commercially reasonable efforts to provide Customer the Services. As part of the registration process, Customer will identify an administrative username and password for Customer's Company account.  Company reserves the right to refuse registration of, or cancel passwords it deems inappropriate.
                </p>
                <p>
                    2.2	Subject to the terms hereof, Company will provide Customer with reasonable technical support services in accordance with Company's standard practice.
                </p>
                <h3>3. Restrictions and Responsibilities</h3>
                <p>
                    3.1	Customer will not, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services ("Software"); modify, translate, or create derivative works based on the Services or any Software (except to the extent expressly permitted by Company or authorized within the Services); use the Services or any Software for timesharing or service bureau purposes or otherwise for the benefit of a third; or remove any proprietary notices or labels.
                </p>
                <p>
                    3.2	Customer represents, covenants, and warrants that Customer will use the Services only in compliance with all applicable laws and regulations. Customer hereby agrees to indemnify and hold harmless Company against any damages, losses, liabilities, settlements and expenses (including without limitation costs and attorneys' fees) in connection with any claim or action that arises from an alleged violation of the foregoing or otherwise from Customer's use of Services. Although Company has no obligation to monitor Customer's use of the Services, Company may do so and may prohibit any use of the Services it believes may be (or alleged to be) in violation of the foregoing.
                </p>
                <p>
                    3.3	Customer shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access or otherwise use the Services, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, "Equipment").  Customer shall also be responsible for maintaining the security of the Equipment, Customer account, passwords (including but not limited to administrative and user passwords) and files, and for all uses of Customer account or the Equipment with or without Customer's knowledge or consent.
                </p>
                <p>
                    3.4	Customer agrees to receive marketing materials or other documentation from Company.
                </p>
                <h3>4. Confidentiality; Proprietary Rights</h3>
                <p>
                    4.1	Each party (the "Receiving Party") understands that the other party (the "Disclosing Party") has disclosed or may disclose business, technical or financial information relating to the Disclosing Party's business (hereinafter referred to as "Proprietary Information" of the Disclosing Party).  Proprietary Information of Company includes non-public information regarding features, functionality and performance of the Service.  Proprietary Information of Customer includes non-public data provided by Customer to Company to enable the provision of the Services ("Customer Data"). The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information.  The Disclosing Party agrees that the foregoing shall not apply with respect to any information after five (5) years following the disclosure thereof or any information that the Receiving Party can document (a) is or becomes generally available to the public, or (b) was in its possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully disclosed to it without restriction by a third party, or (d) was independently developed without use of any Proprietary Information of the Disclosing Party or (e) is required to be disclosed by law.
                </p>
                <p>
                    4.2	Customer shall own all right, title and interest in and to the Customer Data, as well as any data that is based on or derived from the Customer Data and provided to Customer as part of the Services.  Company shall own and retain all right, title and interest in and to (a) the Services and Software, all improvements, enhancements or modifications thereto, (b) any software, applications, inventions or other technology developed in connection with support, and (c) all intellectual property rights related to any of the foregoing.
                </p>
                <p>
                    4.3	Notwithstanding anything to the contrary, Company shall have the right collect and analyze data and other information relating to the provision, use and performance of various aspects of the Services and related systems and technologies (including, without limitation, information concerning Customer Data and data derived therefrom), and  Company will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other Company offerings, and (ii) disclose such data solely in aggregate or other de-identified form in connection with its business. No rights or licenses are granted except as expressly set forth herein. Customer allows Company to use Customer's name and logo for Company's marketing purposes without cost.
                </p>
                <h3>5.	Payment of Fees</h3>
                <p>
                    5.1	Customer will pay Company the then applicable fees described in the Invoice.  If Customer's use of the Services exceeds the Service Capacity set forth on the Invoice or otherwise requires the payment of additional fees (per the terms of this Agreement), Customer shall be billed for such usage and Customer agrees to pay the additional fees in the manner provided herein.  Company reserves the right to change the Fees or applicable charges and to institute new charges and Fees at the end of the current renewal term. If Customer believes that Company has billed Customer incorrectly, Customer must contact Company no later than 60 days after the closing date on the first billing statement in which the error or problem appeared, in order to receive an adjustment or credit.  Inquiries should be directed to Company's customer support department.
                </p>
                <h3>6.	Security, and Privacy</h3>
                <p>
                    6.1	To the extent International data protection laws apply to the processing of personal data between Company and Customer.
                </p>
                <h3>7. Term and Termination</h3>
                <p>
                    7.1	This Agreement shall commence on the Effective Date and shall continue in effect unless either party requests termination at least thirty (30) days prior to the end of the then-current term.
                </p>
                <p>
                    7.2	In addition to any other remedies it may have, either party may also terminate this Agreement if the other party materially breaches any of the terms or conditions of this Agreement.  Customer will pay in full for the Services up to and including the last day on which the Services are provided. In the event of termination, prepaid Fees will not be refunded.
                </p>
                <p>
                    7.3	All sections of this Agreement which by their nature should survive termination will survive termination, including, without limitation, accrued rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.
                </p>
                <h3>8. Warranty and Disclaimer</h3>
                <p>
                    Company shall use reasonable efforts consistent with prevailing industry standards to maintain the Services in a manner which minimizes errors and interruptions in the Services.  Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Company or by third-party providers, or because of other causes beyond Company's reasonable control, but Company shall use reasonable efforts to provide advance notice in writing or by e-mail of any scheduled service disruption.  HOWEVER, COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES.  EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES ARE PROVIDED "AS IS" AND COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                </p>
                <h3>9. Limitation of Liability</h3>
                <p>
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND COMPANY'S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CUSTOMER TO COMPANY FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <h3>10. Miscellaneous</h3>
                <p>
                    If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.  This Agreement is not assignable, transferable or sublicensable by Customer except with Company's prior written consent.  Company may transfer and assign any of its rights and obligations under this Agreement without consent.  This Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement, and that all waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein.  No agency, partnership, joint venture, or employment is created as a result of this Agreement and Customer does not have any authority of any kind to bind Company in any respect whatsoever.  In any action or proceeding to enforce rights under this Agreement, the prevailing party will be entitled to recover costs and attorneys' fees.  All notices under this Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; the day after it is sent, if sent for next day delivery by recognized overnight delivery service; and upon receipt, if sent by certified or registered mail, return receipt requested.  This Agreement shall be governed by the laws of the United Arab Emirates without regard to its conflict of laws provisions.
                </p>
            </div>
            </div>
            <Footer />
        </Layout>
    );
}

export default Services